<template>
     <contacts-list ></contacts-list>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import ContactsList from './ContactsList.vue'
import { required, email} from '@vuelidate/validators'
export default {
      components:{
          ContactsList
      },

data() {
        return {
    
            contact: {},
            contacts: [],
            editCompany: false,
            addContact:false,
            selectedItems: null,
            filters: {},
            isSubmitted: false,
            editDialogcompany: false,
            item:{},
            items: [],
            status:[
                {name:'Développeur', code:'Développeur'},
                {name:'Commercial',code:'Commercial'},
                {name:'Comptable',code:'Comptable'},
                {name:'DevOps',code:'DevOps'},
            ]
           
        }
    },
     validations (){
        return { 
           
            contact: {
                lastName:{required},
                firstName:{required},
                email:{email},
                status:{required},
                companies:[],
               
            },
           
        }
    },
    props:['company'],
   
    setup: () => ({ v$: useVuelidate() }),
  created() {
        this.reloadData();
    },
    methods: {

        reloadData() {
        /*     this.$resources.companies.get(this.id).then((response) => {this.company = response});     */        
        },

    
        openEditCompany() {
            this.editCompany = true;
        },
        addContactDialog() {
            this.addContact = true;
        },
       
        hideDialog() {
            this.addContact = false;
            this.editCompany = false;
        },
        editDialog(){
        this.editDialogcompany= true     
        },
        saveContact() {
        this.isSubmitted = true;
        this.contact.company = []
        this.contact.company.push(this.company['@id'])
            if (this.contact.id) {
                this.$resources.contacts.update(this.contact.id, this.contact).then( (data) => {
                    this.reloadData();
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Contact mise à jour', life: 3000});
                    this.items[this.findIndexById(this.contact.id)] = data;
                    this.editCompany = false;
                    this.editDialogcompany = false;
                    this.contact = {};
                });
            } else {
                this.$resources.contacts.create(this.contact).then( (data) => {
                    console.log(this.contact.company)
                    this.items.push(data);
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Contact ajoutée', life: 3000});

                    this.addContact = false;
                    this.contact = {};
                });
            } 
        },
        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.items.length; i++) {
                if (this.items[i].id === id) {
                    index = i;
                    break;
                }
            }
            return index;
        },
    }
}
</script>

<style scoped lang="scss">
    .table-header {
        display: flex;
        justify-content: space-between;
    }
  strong {
        color: red;
    }
    .detailCompany{
        text-decoration: none;
    }
    
   
    .product-image {
        width: 100px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    .p-dialog .product-image {
        width: 150px;
        margin: 0 auto 2rem auto;
        display: block;
    }

    .confirmation-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product-badge {
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;

        &.status-instock {
            background: #C8E6C9;
            color: #256029;
        }

        &.status-outofstock {
            background: #FFCDD2;
            color: #C63737;
        }

        &.status-lowstock {
            background: #FEEDAF;
            color: #8A5340;
        }
    }

    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;
        .p-button {
            margin-bottom: .25rem;
        }
    }
</style>